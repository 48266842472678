<template>
  <v-col
    cols="12"
    md="6"
    xl="4"
  >
    <v-hover>
      <!--Card-->
      <v-card
        class="mx-auto product-card"
      >
        <!--Photo-->
        <v-img
          :src="data.photo"
          :data-test="getDataTestId()"
          :lazy-src="imagePlaceholder()"
          aspect-ratio="1.95"
        />

        <!--Title and description-->
        <v-card-title primary-title>
          <div>
            <p>
              <v-icon
                left
                color="primary"
              >
                {{ data.icon }}
              </v-icon>
              <span class="text-h5 mb-0">
                {{ data.title }}
              </span>
            </p>
            <p class="text-subtitle-2">
              {{ data.description }}
            </p>
          </div>
        </v-card-title>

        <!--Actions-->
        <v-card-actions class="pl-3 pt-2 pb-3">
          <!--Action button-->
          <v-btn
            v-for="(item, index) in data.sections"
            :key="index"
            :to="item.link"
            outlined
            color="primary"
          >
            <v-icon left>
              {{ item.icon }}
            </v-icon>
            <span>{{ item.title }}</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-hover>
  </v-col>
</template>

<script>
import { mapGetters } from 'vuex';
import storeConstants from '@/constants/store';

import VueTypes from 'vue-types';
import isNil from 'lodash.isnil';
import imagePlaceholder from '@/utils/imagePlaceholder';
import schema from '@/components/home/product/utils/schema';

const { APP } = storeConstants;

export default {
  name: 'Product',

  props: {
    data: VueTypes.shape(schema.product).isRequired,
  },

  data() {
    return {
      imagePlaceholder,
    };
  },

  computed: {
    ...mapGetters({
      getTenant: `${APP.USER_INFO}/getTenant`,
    }),
  },

  methods: {
    hasMenu(item) {
      return !isNil(item.menu);
    },

    getDataTestId() {
      return this.data.title.toLowerCase().replace(' ', '-');
    },
  },
};
</script>
<style lang="scss">

</style>
