<template>
  <v-app>
    <Spinner />
    <Snackbar />
    <ConfirmDialog />

    <!--The app is ready-->
    <div
      v-if="isAppReady"
      class="min-height-100"
    >
      <SideNavbar />
      <ContextualNavigation />
      <v-main class="min-height-100">
        <v-container
          class="min-height-100 d-flex flex-column"
          fluid
        >
          <!-- CVO-3582 - Show alert to avoid incidents in prod -->
          <v-alert
            v-if="showEnvironmentAlert"
            border="top"
            colored-border
            type="info"
            elevation="6"
            dismissible
          >
            {{ $t('app.info.environment', {env: currentEnvironment}) }}
          </v-alert>
          <Breadcrumbs />
          <router-view class="flex-grow-1" />
        </v-container>
      </v-main>
    </div>

    <!--There was a problem-->
    <div v-if="isAppError">
      <v-main>
        <v-container fluid>
          <Error500 :message="error500Message" />
        </v-container>
      </v-main>
    </div>
  </v-app>
</template>

<script>
import IdleActivator from 'idle-js';
import { mapState, mapActions, mapGetters } from 'vuex';

// App routes
import routesNames from '@/router/routes-names';

// i18n messages
import { requestAppMessages } from '@/i18n';

// App constants
import appConstants from '@/constants/app';
import storeConstants from '@/constants/store';

// App utils
import { getPrimaryColor } from '@/utils/theme';
import changeFaviconByTenant from '@/utils/changeFaviconByTenant';
// App middleware
import auth from '@/middleware/auth';
import hasActiveSession from '@/middleware/auth/utils/hasActiveSession';

// App components
import Spinner from '@/components/app/spinner/spinner.vue';
import Snackbar from '@/components/app/snackbar/snackbar.vue';
import Error500 from '@/components/app/error500/error500.vue';
import SideNavbar from '@/components/app/side-navbar/side-navbar.vue';
import ConfirmDialog from '@/components/app/confirm-dialog/confirm-dialog.vue';
import ContextualNavigation from '@/components/app/contextual-navigation/contextual-navigation.vue';
import Breadcrumbs from '@/components/app/breadcrumbs/breadcrumbs.vue';
import { isEnvProduction, currentEnvironment } from '@/utils/getEnvironments';

const { APP } = storeConstants;
const { DIALOG, STATUS } = appConstants;
const { callback } = routesNames;

export default {
  name: 'App',

  metaInfo: {
    title: 'Columbus',
    titleTemplate: '%s | Columbus',
  },

  components: {
    Spinner,
    Snackbar,
    Error500,
    SideNavbar,
    ConfirmDialog,
    ContextualNavigation,
    Breadcrumbs,
  },

  data() {
    return {
      isAppReady: false,
      isAppError: false,
      error500Message: '',
      idleActivator: null,
      currentEnvironment,
    };
  },

  computed: {
    ...mapState([APP.USER_INFO]),

    ...mapGetters({
      dialogId: `${APP.CONFIRM_DIALOG}/id`,
      dialogCanceled: `${APP.CONFIRM_DIALOG}/canceled`,
      hasAccessToExchange: `${APP.USER_INFO}/hasAccessToExchange`,
      hasAccessToUpsells: `${APP.USER_INFO}/hasAccessToUpsells`,
    }),

    showEnvironmentAlert() {
      return !isEnvProduction;
    },
  },

  watch: {
    /**
     * @description Watch the dialog cancelation
     */
    dialogCanceled: {
      handler() {
        if (this.dialogId === DIALOG.APP.IDLE) {
          auth.then((client) => client.logout());
        }
      },
    },
  },

  async created() {
    this.authorizeUser();
  },

  methods: {
    ...mapActions({
      showSpinner: `${APP.SPINNER}/show`,
      hideSpinner: `${APP.SPINNER}/hide`,
      showSnackbar: `${APP.SNACKBAR}/show`,
      showConfirmDialog: `${APP.CONFIRM_DIALOG}/show`,
      requestProfile: `${APP.USER_INFO}/requestProfile`,
      requestMasterData: `${APP.MASTERDATA}/requestMasterData`,
      requestCurrentDate: `${APP.SERVER_DATE}/requestCurrentDate`,
      updateUserLoginInfo: `${APP.UPDATE_LOGIN_INFO}/updateUserLoginInfo`,
    }),

    async authorizeUser() {
      this.showSpinner(this.$t('app.spinner.loading'));

      try {
        // Case #1: when the user is coming from Auth0's callback after login
        if (this.redirectFromAuthCallback()) {
          await auth.then(async (client) => client.handleRedirectCallback());
          this.updateUserLoginInfo();
        }

        // Case #2: when the user is Cypress
        if (window.Cypress) {
          setTimeout(() => {
            this.getConfigurationData();
          }, 4000);
        }

        // Case #3: when the user has an active session
        if (hasActiveSession()) {
          this.getConfigurationData();
        }
      } catch (error) {
        this.showSnackbar({
          message: this.$t(error.message),
          status: STATUS.ERROR,
        });
      }
    },

    async getConfigurationData() {
      try {
        await this.requestProfile();

        if (this.hasAccessToExchange || this.hasAccessToUpsells) {
          await this.requestMasterData();
        }

        await this.requestCurrentDate();

        await this.setThemeByTenant();

        await requestAppMessages();

        this.setFaviconByTenant();

        this.initIdleActivator();

        this.isAppReady = true;
      } catch (error) {
        /**
         * There was some error requesting the
         * basic configuration for bootstrapping the app
         */
        this.isAppError = true;
        this.error500Message = this.$t(error.message);
      }

      this.hideSpinner();
      this.removeHashFromUrl();
    },

    initIdleActivator() {
      this.idleActivator = new IdleActivator({
        /**
         * Maximum idle time on milliseconds:
         * - Dev: 300000 (5 minutes)
         * - Production & Staging: 1800000 (30 minutes)
         */
        idle: process.env.VUE_APP_MAX_IDLE_TIME_MS,
        onIdle: () => this.handleIndleActivator(),
      });

      this.idleActivator.start();
    },

    handleIndleActivator() {
      this.showConfirmDialog({
        id: DIALOG.APP.IDLE,
        icon: this.$t('app.icons.alert'),
        title: this.$t('app.idleActivator.title'),
        text: this.$t('app.idleActivator.text'),
        countdown: {
          show: true,
        },
        buttons: {
          cancelation: this.$t('app.idleActivator.buttons.cancelation'),
          confirmation: this.$t('app.idleActivator.buttons.confirmation'),
        },
      });
    },

    async setThemeByTenant() {
      this.$vuetify.theme.themes.light.primary = getPrimaryColor(this.appUserInfo.tenant);
    },

    setFaviconByTenant() {
      changeFaviconByTenant(this.appUserInfo.tenant);
    },

    redirectFromAuthCallback() {
      return this.$route.name === callback;
    },

    removeHashFromUrl() {
      // this method only exits for cosmetic reasons, because keycloak add some parameters to the URL and we hide them
      // if it causes problems on the future, feel free to remove it.

      // eslint-disable-next-line no-restricted-globals
      history.replaceState(null, '', window.location.href.split('#')[0]);
    },
  },
};
</script>
