import routesNames from '@/router/routes-names';
import appConstants from '@/constants/app';
import teantConstants from '@/constants/tenant';
import { getCommonProductImage } from '@/utils/theme';

// App
const { DASHBOARDS } = appConstants.SECTIONS;

// Routes
const { subscriptions } = routesNames;

// i18n
const section = `app.${DASHBOARDS}`;

/**
 * @description Dashboards data
 * @this Vue component
 * @returns {Object}
 */
export default function dashboardsData() {
  const data = {
    icon: this.$t('app.icons.dashboard'),
    photo: getCommonProductImage(DASHBOARDS),
    title: this.$t(`${section}.title`),
    description: this.$t(`${section}.description`),
    sections: [],
  };

  // Enabled subscription products (club, subs, unlimited) by tenant
  const enabledProducts = teantConstants[this.getTenant].ENABLED_PRODUCTS;

  const productButtons = enabledProducts.map((product) => ({
    icon: this.$t('app.icons.dashboard'),
    title: this.$t(`tenant.${this.getTenant}.subs_product.${product}`),
    link: `/${subscriptions.main}/${subscriptions.dashboard.main}/${product}`,
  }));

  data.sections = productButtons;

  return data;
}
