<template>
  <transition name="contextual-menu">
    <v-card
      v-if="contextualDisplay"
      width="300"
      :class="getClass()"
    >
      <!--Close-->
      <div class="close text-sm-right">
        <v-btn
          fab
          small
          color="primary"
          @click="handleClose"
        >
          <v-icon>
            {{ $t("app.icons.chevronLeft") }}
          </v-icon>
        </v-btn>
      </div>

      <div class="menu">
        <!--Search-->
        <SearchByPNR v-if="isMenuSearch()" />

        <!--Menu-->
        <v-list
          v-for="item in getMenuData()"
          v-else
          :key="item.title"
        >
          <UserInfo v-if="isMenuProfile()" />

          <v-subheader
            v-else
            class="text-subtitle-1"
          >
            {{ item.title }}
          </v-subheader>

          <v-list-item
            v-for="section in item.sections"
            :key="section.title"
            :to="section.link"
            :title="section.title"
            @click="handleOpen(section.link)"
          >
            <v-list-item-title>
              <v-icon left>
                {{ section.icon }}
              </v-icon>
              {{ section.title }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </div>

      <Footer v-if="isMenuProfile()" />
    </v-card>
  </transition>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

// Components
import Footer from '@/components/app/footer/footer.vue';
import UserInfo from '@/components/app/user-info/user-info.vue';
import SearchByPNR from '@/components/search-by-pnr/search-box/search-box.vue';

// Contextual data
import getUserData from '@/utils/userData/userData';
import getUpsellsData from '@/utils/productData/upsellsData';
import getSettingsData from '@/utils/settingsData/settingsData';
import getChatbotData from '@/utils/productData/chatbotData';
import getCouponPassData from '@/utils/productData/couponpassData';
import getSubscriptionsData from '@/utils/productData/subscriptionsData';
import getExchangeData from '@/utils/productData/exchangeData';
import getGiftVoucherData from '@/utils/productData/giftVoucherData';
import getDashboardsData from '@/utils/sectionData/dashboardsData';

// Constants
import appConstants from '@/constants/app';
import storeConstants from '@/constants/store';

// Store
const { APP } = storeConstants;

// App
const { PRODUCTS, SIDE_NAVBAR } = appConstants;
const { MENU } = SIDE_NAVBAR;
const {
  UPSELLS, CHATBOT, SUBSCRIPTIONS, COUPONPASS, EXCHANGE, GIFT_VOUCHER, DASHBOARDS,
} = PRODUCTS;

export default {
  name: 'ContextualNavigation',

  components: {
    Footer,
    UserInfo,
    SearchByPNR,
  },

  computed: {
    ...mapGetters({
      mini: `${APP.MENU}/sideNavBarMini`,
      contextualType: `${APP.MENU}/contextualType`,
      contextualDisplay: `${APP.MENU}/contextualDisplay`,
      getUserRole: `${APP.USER_INFO}/getUserRole`,
      getTenant: `${APP.USER_INFO}/getTenant`,
      hasAccessToExchange: `${APP.USER_INFO}/hasAccessToExchange`,
      hasUserAdminPrivileges: `${APP.USER_INFO}/hasUserAdminPrivileges`,
      isPromosEnabled: `${APP.FEATURES}/isPromosEnabled`,
    }),

    dataGetters() {
      return {
        [EXCHANGE]: getExchangeData,
        [UPSELLS]: getUpsellsData,
        [CHATBOT]: getChatbotData,
        [SUBSCRIPTIONS]: getSubscriptionsData,
        [COUPONPASS]: getCouponPassData,
        [GIFT_VOUCHER]: getGiftVoucherData,
        [MENU.PROFILE]: getUserData,
        [MENU.SETTINGS]: getSettingsData,
        [DASHBOARDS]: getDashboardsData,
      };
    },
  },

  methods: {
    ...mapActions({
      setContextualType: `${APP.MENU}/setContextualType`,
    }),

    getSectionMenu(sections) {
      const [head] = sections.map((section) => section.menu);
      return head;
    },

    /**
     * Get class
     *
     * @returns {String}
     */
    getClass() {
      const defaultClass = 'mx-auto fill-height contextual-navigation';

      if (this.mini) {
        return defaultClass;
      }

      return `${defaultClass} large`;
    },

    /**
     * Check if the menu type is profile
     *
     * @returns {Boolean}
     */
    isMenuProfile() {
      return this.checkMenuType(MENU.PROFILE);
    },

    /**
     * Check if the menu type is search
     *
     * @returns {Boolean}
     */
    isMenuSearch() {
      return this.checkMenuType(MENU.SEARCH);
    },

    /**
     * Check menu type
     *
     * @param {String} type - Menu type
     * @returns {Boolean}
     */
    checkMenuType(type) {
      return this.contextualType === type;
    },

    /**
     * Get menu data
     *
     * @returns {Array<Object>}
     */
    getMenuData() {
      const getter = this.dataGetters[this.contextualType];

      const data = getter
        ? [getter.call(this)]
        : [{ title: this.$t('app.error.contextualNavigation.noData') }];

      return data;
    },

    /**
     * Handle open
     */
    handleOpen() {
      this.setContextualType(null);
    },

    /**
     * Handle close
     */
    handleClose() {
      this.setContextualType(null);
    },
  },
};
</script>

<style lang="scss">
.contextual-navigation {
  left: 80px;
  position: fixed;
  z-index: 3;

  &.large {
    left: 256px;
  }

  .menu {
    margin-top: 70px;
  }

  .close {
    top: 10px;
    right: -18px;
    position: absolute;
  }
}
</style>
