<template>
  <v-container
    class="pa-3"
    fluid
  >
    <v-row>
      <Product
        v-for="(product, index) in getProducts()"
        :key="index"
        :data="product"
      />
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';

// Constants
import storeConstants from '@/constants/store';

// Components
import Product from '@/components/home/product/product.vue';

// Utils
import hasAccessToReports from '@/utils/hasAccessToReports';
import hasAccessToDashboards from '@/utils/hasAccessToDashboards';
import getUpsellsData from '@/utils/productData/upsellsData';
import getChatbotData from '@/utils/productData/chatbotData';
import getSubscriptionsData from '@/utils/productData/subscriptionsData';
import getExchangeData from '@/utils/productData/exchangeData';
import getReportsData from '@/utils/sectionData/reportsData';
import getDashboardsData from '@/utils/sectionData/dashboardsData';
import getGiftVoucherData from '@/utils/productData/giftVoucherData';
import getCouponPassData from '@/utils/productData/couponpassData';

const { APP } = storeConstants;

export default {
  name: 'HomeView',

  metaInfo: {
    title: 'Home',
  },

  components: {
    Product,
  },

  computed: {
    ...mapGetters({
      getTenant: `${APP.USER_INFO}/getTenant`,
      getUserRole: `${APP.USER_INFO}/getUserRole`,
      hasAccessToChatbot: `${APP.USER_INFO}/hasAccessToChatbot`,
      hasAccessToSubscriptions: `${APP.USER_INFO}/hasAccessToSubscriptions`,
      hasAccessToUpsells: `${APP.USER_INFO}/hasAccessToUpsells`,
      hasAccessToExchange: `${APP.USER_INFO}/hasAccessToExchange`,
      hasAccessToGiftVoucher: `${APP.USER_INFO}/hasAccessToGiftVoucher`,
      hasAccessToCouponPass: `${APP.USER_INFO}/hasAccessToCouponPass`,
      isPromosEnabled: `${APP.FEATURES}/isPromosEnabled`,
      userRole: `${APP.USER_INFO}/getUserRole`,
    }),
  },

  methods: {
    /**
          * Get product list
          * @returns {Array<Object>}
          */
    getProducts() {
      const products = [];

      if (this.hasAccessToExchange) {
        const exchange = getExchangeData.call(this);
        products.push(exchange);
      }

      if (this.hasAccessToUpsells) {
        const upsells = getUpsellsData.call(this);
        products.push(upsells);
      }

      if (this.hasAccessToChatbot) {
        const chatbot = getChatbotData.call(this);
        products.push(chatbot);
      }

      if (this.hasAccessToSubscriptions) {
        const subscriptions = getSubscriptionsData.call(this);
        products.push(subscriptions);
      }

      if (this.hasAccessToCouponPass) {
        const couponpass = getCouponPassData.call(this);
        products.push(couponpass);
      }

      if (this.hasAccessToGiftVoucher) {
        const giftVoucher = getGiftVoucherData.call(this);
        products.push(giftVoucher);
      }

      if (hasAccessToReports(this.getTenant, this.userRole)) {
        const reports = getReportsData.call(this);
        products.push(reports);
      }

      if (hasAccessToDashboards(this.getTenant, this.userRole)) {
        const reports = getDashboardsData.call(this);
        products.push(reports);
      }

      return products;
    },
  },
};
</script>
