import store from '@/store';
import storeConstants from '@/constants/store';
import tenantConstants from '@/constants/tenant';

const { APP } = storeConstants;

// const allowedTenants = (process.env.VUE_APP_DASHBOARDS_ENABLED_TENANTS || '').split(',');

const dashboardRoles = store.getters[`${APP.FEATURES}/getDashboardRoles`];

/**
 * Checks if the given tenant has access to reports
 *
 * @param {string} tenant - The tenant code to check
 * @returns {boolean}
 */
export default function hasAccessToDashboards(tenant, userRole) {
  const hasEnabledSubsProduct = Object.keys(tenantConstants).includes(tenant);
  return hasEnabledSubsProduct && dashboardRoles.includes(userRole);
}
