<template>
  <div>
    <v-list-item
      v-for="(item, index) in getMenu()"
      :key="index"
      :ref="item.type"
      :title="item.title"
      @click="item.handler(item.type)"
    >
      <v-list-item-action>
        <v-icon>
          {{ item.icon }}
        </v-icon>
      </v-list-item-action>

      <v-list-item-content>
        <v-list-item-title>
          {{ item.title }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import appConstants from '@/constants/app';
import storeConstants from '@/constants/store';
import routesNames from '@/router/routes-names';
import hasAccessToReports from '@/utils/hasAccessToReports';
import hasAccessToDashboards from '@/utils/hasAccessToDashboards';

const { APP } = storeConstants;
const { TENANT, SIDE_NAVBAR, PRODUCTS } = appConstants;
const { MENU } = SIDE_NAVBAR;
const {
  UPSELLS,
  CHATBOT,
  SUBSCRIPTIONS,
  EXCHANGE,
  GIFT_VOUCHER,
  COUPONPASS,
} = PRODUCTS;

const { reports, chatbot } = routesNames;

export default {
  name: 'SideNavBarMenu',

  computed: {
    ...mapGetters({
      getTenant: `${APP.USER_INFO}/getTenant`,
      userRole: `${APP.USER_INFO}/getUserRole`,
      hasAccessToChatbot: `${APP.USER_INFO}/hasAccessToChatbot`,
      hasAccessToSubscriptions: `${APP.USER_INFO}/hasAccessToSubscriptions`,
      hasAccessToCouponPass: `${APP.USER_INFO}/hasAccessToCouponPass`,
      hasAccessToUpsells: `${APP.USER_INFO}/hasAccessToUpsells`,
      hasAccessToExchange: `${APP.USER_INFO}/hasAccessToExchange`,
      hasAccessToGiftVoucher: `${APP.USER_INFO}/hasAccessToGiftVoucher`,
      hasUserEnoughPrivileges: `${APP.USER_INFO}/hasUserEnoughPrivileges`,
    }),
  },

  methods: {
    ...mapActions({
      setContextualType: `${APP.MENU}/setContextualType`,
    }),

    /**
     * Check if the user has access to search
     *
     * @returns {Boolean}
     */
    hasAccessToSearch() {
      return (
        this.hasAccessToExchange
        || (
          this.hasAccessToUpsells
          // AirAsia doesn't have access to Bids
          && this.getTenant !== TENANT.D7
        )
      );
    },

    /**
     * Open contextual menu by type
     */
    openContextualMenu(type) {
      const [first] = this.$refs[type];
      first.$el.focus();

      this.setContextualType(type);
    },

    /**
     * Navigates to report section
     */
    goToReports() {
      if (this.$route.name !== reports.main) {
        this.$router.push({
          name: reports.main,
        });
      }
    },

    /**
     * Navigates to chatbot settings section
     */
    goToBotSettings() {
      if (this.$route.name !== `${chatbot.main}.${chatbot.settings.main}`) {
        this.$router.push({
          name: `${chatbot.main}.${chatbot.settings.main}`,
        });
      }
    },

    /**
     * Get product data
     *
     * @param {String} type - The product type
     * @returns {Object}
     */
    getProductData(type) {
      const product = `app.products.${type}`;

      return {
        type,
        handler: this.openContextualMenu,
        icon: this.$t(`${product}.icon`),
        title: this.$t(`${product}.title.${this.getTenant}`),
      };
    },

    /**
     * Get search data
     *
     * @returns {Object}
     */
    getSearchData() {
      return {
        type: MENU.SEARCH,
        handler: this.openContextualMenu,
        icon: this.$t('app.icons.search'),
        title: this.$t('app.search.title'),
      };
    },

    /**
     * Get reports data
     *
     * @returns {Object}
     */
    getReportsData() {
      return {
        type: MENU.REPORTS,
        handler: this.goToReports,
        icon: this.$t('app.icons.reports'),
        title: this.$t('app.reports.title'),
      };
    },

    /**
     * Get dashboards data
     *
     * @param {String} type - The product type
     * @returns {Object}
     */
    getDashboardsData() {
      return {
        type: MENU.DASHBOARDS,
        handler: this.openContextualMenu,
        icon: this.$t('app.icons.dashboard'),
        title: this.$t('app.dashboards.title'),
      };
    },

    /**
     * Get chatbots data
     *
     * @returns {Object}
     */
    getChatbotsSettingsData() {
      return {
        type: MENU.BOT_SETTINGS,
        handler: this.goToBotSettings,
        icon: this.$t('app.icons.robot'),
        title: this.$t('app.products.bot.sections.settings.title'),
      };
    },

    /**
     * Get menu
     *
     * @returns {Array<Object>}
     */
    getMenu() {
      const menu = [];

      if (this.hasAccessToExchange) {
        menu.push(this.getProductData(EXCHANGE));
      }

      if (this.hasAccessToUpsells) {
        menu.push(this.getProductData(UPSELLS));
      }

      /**
       * CVO-3102
       * In order to add the new 'EDO' tenant
       * we must disable the access of EDO users
       * to the dashboard of chatbots
       */
      if (this.hasAccessToChatbot) {
        menu.push(this.getProductData(CHATBOT));
      }
      if (this.hasAccessToSubscriptions) {
        menu.push(this.getProductData(SUBSCRIPTIONS));
      }

      if (this.hasAccessToCouponPass) {
        menu.push(this.getProductData(COUPONPASS));
      }

      if (this.hasAccessToGiftVoucher) {
        menu.push(this.getProductData(GIFT_VOUCHER));
      }

      if (this.hasAccessToSearch()) {
        menu.push(this.getSearchData());
      }

      if (hasAccessToReports(this.getTenant, this.userRole)) {
        menu.push(this.getReportsData());
      }

      if (hasAccessToDashboards(this.getTenant, this.userRole)) {
        menu.push(this.getDashboardsData());
      }

      return menu;
    },
  },
};
</script>
